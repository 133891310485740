<template>
    <el-row v-if="searchStr.length>0 && itemTotal>0"  style="width:1440px;margin:0 auto;background:#fff;">
        <el-col :span="4" >
            <div>
                <div class="search-title">
                     <span>指标列表</span>
                </div>
                <el-scrollbar class="search-item-wrap" >
                    <ul>
                        <li v-for="item in itemList" :key="item.itemId" :title='item.itemName' :class="{'active':selItem?.id==item.itemId}" @click="getRegionItemNumListFun(item.itemId)" >
                            <span class="search-item-name">{{item.itemName}}</span>
                            <span class="search-item-num"> {{"("+item.num+")"}}</span>
                        </li>
                    </ul>
                </el-scrollbar>
                <div v-if="isMore" style="text-align: center; padding:10px;">
                    <el-button type="primary" @click="getItemNumListFun(2)">加载更多</el-button>
                    <!-- <el-link :underline="false">加载更多</el-link> -->
                </div>
            </div>
        </el-col>
        <el-col :span="20" style="padding:0 20px 20px 20px;border-left: 1px solid rgb(220, 223, 230);">
            <div>
                <div class="search-title">
                    <span>范围选择</span>
                </div>
                <div v-loading="loading" >
                    <div class="search-region-wrap clearfix">
                        <span class="item-title fontsize16">地区：</span>
                        <!-- 省级地区 -->
                        <ul class="clearfix">
                            <li v-for="region in regionList?.filter(t=> t.parent=='' || t.parent=='0' )" :key="region.code" :class="{'active':proviceCode==region.code,'is-disabled':region.disabled}" @click='getNextRegionItemNumFun("provice",region.code,region.disabled)'>
                                <span class="search-item-name">{{region.name}}</span>
                                <span class="search-item-num">{{region.itemNum?'('+region.itemNum+')':''}}</span>
                            </li>
                        </ul>
                        <!-- 市级地区 -->
                            <ul class="search-region-sub clearfix">
                            <li v-for="region in regionList?.filter(t=>t.parent==proviceCode && proviceCode!='0' )" :key="region.code" :class="{'active':cityCode==region.code,'is-disabled':region.disabled}" @click='getNextRegionItemNumFun("city",region.code,region.disabled)'>
                                <span class="search-item-name">{{region.name}}</span>
                                <span class="search-item-num">{{region.itemNum?'('+region.itemNum+')':''}}</span>
                            </li>
                        </ul>
                        <!-- 区县级地区 -->
                        <ul class="search-region-sub clearfix">
                            <li v-for="region in regionList?.filter(t=>t.parent==cityCode )" :key="region.code" :class="{'active':districtCode==region.code,'is-disabled':region.disabled}" @click='getNextRegionItemNumFun("district",region.code,region.disabled)'>
                                <span class="search-item-name">{{region.name}}</span>
                                <span class="search-item-num">{{region.itemNum?'('+region.itemNum+')':''}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="search-region-wrap clearfix" style="min-height:60px;">
                        <span class="item-title fontsize16">行业：</span>
                        <!-- 门类 -->
                        <ul class="clearfix">
                            <li v-for="item in ecoIndustryList?.filter(t=> t.parent=='' || t.parent=='0' )" :key="item.code" :class="{'active':ecoIndustryCode==item.code}" @click='getNextEcoIndustryItemNumFun("ecoIndustry",item.code)'>
                                <span class="search-item-name">{{item.name}}</span>
                                <span class="search-item-num">{{item.itemNum?'('+item.itemNum+')':''}}</span>
                            </li>
                        </ul>
                        <!-- 大类 -->
                            <ul class="search-region-sub clearfix">
                            <li v-for="item in ecoIndustryList?.filter(t=>t.parent==ecoIndustryCode )" :key="item.code" :class="{'active':ecoIndustryBigCode==item.code}" @click='getNextEcoIndustryItemNumFun("ecoIndustryBig",item.code)'>
                                <span class="search-item-name">{{item.name}}</span>
                                <span class="search-item-num">{{item.itemNum?'('+item.itemNum+')':''}}</span>
                            </li>
                        </ul>
                        <!-- 中类 -->
                        <ul class="search-region-sub clearfix">
                            <li v-for="item in ecoIndustryList?.filter(t=>t.parent==ecoIndustryBigCode )" :key="item.code" :class="{'active':ecoIndustryMiddleCode==item.code}" @click='getNextEcoIndustryItemNumFun("ecoIndustryMiddle",item.code)'>
                                <span class="search-item-name">{{item.name}}</span>
                                <span class="search-item-num">{{item.itemNum?'('+item.itemNum+')':''}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div class="search-title">
                    <span>数据列表</span>
                    <!-- 图表 -->
                    <i class="iconfont ec-icon-s-data" :class="{'active':selIconId==2}" @click="changIcon(2)"></i>
                    <!-- 列表 -->
                    <i class='iconfont ec-icon-menu' :class="{'active':selIconId==1}" @click="changIcon(1)"></i>
                </div>
                <el-table v-loading="loading"  v-if="selIconId==1" :data="dataList" stripe style="width: 100%" size='small' table-layout='fixed' :height="dataList?.length>10?440:null">
                    <el-table-column prop="date" label="日期" width="180" >
                        <template #default="scope">
                            <span>{{ scope.row.date?parseTimeOfCycle(scope.row.date,'1'):'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="itemValue" >
                        <template #header>
                                <span>{{itemInfo? itemInfo?.itemName+'('+itemInfo?.itemUnit+')':'实际值'}}</span>
                                <item-favorite v-has='{action:"collect"}' :itemId='selItem?.id' name='collect' :objId='selEcoIndustryCode'  :isChange='isChangeFavorite'></item-favorite>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.itemValue?scope.row.itemValue:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="forecastVal" label="预测值" >
                        <template #default="scope">
                            <span>{{ scope.row.forecastVal?scope.row.forecastVal:'-' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="selIconId==2" >
                        <div style="text-align:right;padding-right:20px;">
                            <el-switch
                            v-model="isDarkTheme"
                            active-text="深色模式"
                            inactive-text="" @change='initChart'/>
                            <el-button v-has='{action:"download"}' type="text" style="margin-left:10px;" @click="download('download')" ><i class="iconfont ec-icon-download"></i></el-button>
                    </div>
                    <div id="curve_l" style="height:450px;width:100%;">
                    </div>
                </div>
            </div>
            <div>
                <div class="search-title">
                <span>元数据</span>
                </div>
                <div v-loading="loading" >
                    <div class="item-row">
                        <span class="item-title">指标项名：</span>
                        <span class="item-info">{{itemInfo?.itemName}}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-title">单位：</span>
                        <span class="item-info">{{itemInfo?.itemUnit}}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-title">更新周期：</span>
                        <span class="item-info">{{itemInfo?.itemCycleName}}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-title">指标等级：</span>
                        <span class="item-info">{{itemInfo?.itemGradeName}}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-title">指标来源：</span>
                        <span class="item-info">{{itemInfo?.itemSource}}</span>
                    </div>
                    <div class="item-row">
                        <span class="item-title">指标描述：</span>
                        <span class="item-info">{{itemInfo?.itemDescr}}</span>
                    </div>
                    <div class="flex_row">
                        <div class="flex-col">
                            <span class="fontsize20">最大值</span> 
                            <span class="num">{{maxVal}}</span>
                        </div>
                        <div class="flex-col">
                            <span class="fontsize20">最小值</span>
                            <span class="num">{{minVal}}</span>
                            </div>
                        <div class="flex-col">
                            <span class="fontsize20">平均值</span>
                            <span class="num">{{avgVal}}</span>
                        </div>
                        <div class="flex-col">
                            <span class="fontsize20">中位数</span>
                            <span class="num">{{middleVal}}</span>
                            </div>
                    </div>
                </div>
            </div>
        </el-col>
    </el-row>
    <search-no-result v-if='searchStr.length<=0 || itemTotal==0' :search="searchStr" type="ecoIndustry"></search-no-result>
</template>
<script>
import SearchNoResult from '@/components/SearchNoResult'
import ItemFavorite from '@/components/ItemFavorite'
import { useRoute} from 'vue-router'
import { ref } from '@vue/reactivity'
import * as echarts from 'echarts'
import {parseTime,parseTimeOfCycle ,base64ToBlob} from '@/utils'
import {getItemNumListOfEcoIndustry,getRegionItemNumOfEcoIndustry,getEcoIndustryClassItemNum,getItemInfoOfEcoIndustry,getItemDataListOfEcoIndustry,getEcoIndustryItemData_chart} from '@/http/ecoApi.js'
import {addUserLog,getUserRightFun} from '@/http/basicsApi.js'
import { nextTick } from '@vue/runtime-core'
import axios from 'axios'
export default {
    components: {
        SearchNoResult,
        ItemFavorite
    },
    setup() {
        const route =useRoute(); //useRoute();//当前路由
        //const router = useRouter()
        let searchStr=ref(route.query['key']?route.query['key']:'')//搜索内容
        let loading=ref(false);//正在加载
        let isChangeFavorite=ref('')//是否显示收藏

        //获取指标项列表
        let itemList=ref([]);
        let selItem=ref(null);//选中的指标项
        let pageIndex=ref(1);//指标项列表的页数
        const pageSize=ref(20);//指标项列表的每页数量
        let itemTotal=ref(0);//指标项列表的总数
        let isMore=ref(false);//是否有“加载更多”，默认没有
        const getItemNumListFun= async(type)=>{//type,1:重新加载指标项列表，2：加载更多指标项列表
            if(type==1){
                pageIndex.value=1;
                itemList.value=[];
            }
            else if(type==2){
                pageIndex.value=pageIndex.value+1;
            }
            let params={
                search:searchStr.value,
                pageIndex:pageIndex.value,
                pageSize:pageSize.value
            }
            //console.log(params);
            const result= await getItemNumListOfEcoIndustry(params);//指标项值列表
            //console.log(result);
            if( result.code==20000){
                itemTotal.value=result.data.totalCount;
                itemList.value=[...itemList.value, ...result.data.dataList];
                isMore.value=itemTotal.value<=pageIndex.value *pageSize.value?false:true;
                if(type==1){
                    //默认第一指标项
                    selItem.value={id:itemList.value[0].itemId};
                    getRegionItemNumListFun(selItem.value.id);
                }
            }
            else if(type==1){
                itemList.value=[];
            }
            
        }
        //获取地区的指标数据量列表
        let regionList=ref(null);
        let proviceCode=ref('-1')//选中的省级地区编号
        let cityCode=ref('-1')//选中的市级地区编号
        let districtCode=ref('-1')//选中的区县级编号
        let selRegionCode=ref('-1');//选中的地区编号
        let ecoIndustryList=ref(null);//行业分类
        let ecoIndustryCode=ref('-1')//选中的行业门类编号
        let ecoIndustryBigCode=ref('-1')//选中的行业大类编号
        let ecoIndustryMiddleCode=ref('-1')//选中的行业中类编号
        let selEcoIndustryCode=ref('-1');//选中的行业分类编号
        const getRegionItemNumListFun= async(itemId)=>{
            selItem.value={id:itemId};
            selIconId.value=1;//默认列表显示
            loading.value=true;
            proviceCode.value='-1';
            cityCode.value='-1';
            districtCode.value='-1';
            selRegionCode.value='-1';
            ecoIndustryCode.value='-1'//选中的行业门类编号
            ecoIndustryBigCode.value='-1'//选中的行业大类编号
            ecoIndustryMiddleCode.value='-1'//选中的行业中类编号
            selEcoIndustryCode.value='-1';
            dataList.value=null;
            let params={
                ids:itemId
            }
            //console.log(params);
            axios.all([getRegionItemNumOfEcoIndustry(params), getEcoIndustryClassItemNum(params)])
            .then(axios.spread(function (resp1, resp2) {	// spread是将各请求结果拆分返回，如果不使用改方法，则最后的相应结果为一个数组[resp1, resp2]
                // 两个请求现在都执行完成
                //console.log(resp1);
                //console.log(resp2);
                if(resp1.code==20000){
                    //循环地区列表，对本地区没有数量和下级没有数量的地区禁用（不能点击）
                    resp1.data.filter(t=>t.parent=='' || t.parent=='0').forEach(element => {
                        let childAry=resp1.data.filter(t=>t.parent==element.code && t.parent!='0');
                        //本地区是否有数据量
                        if(element.itemNum>0){
                            if(selRegionCode.value=='-1'){
                                selRegionCode.value=element.code;
                                proviceCode.value=element.code;
                            }
                            element.disabled=false;
                        }
                        else if(childAry.length>0 && childAry.filter(t=>t.itemNum>0).length>0){
                            element.disabled=false;
                        }
                        else{
                            element.disabled=true;
                        }
                        //下级地区是否有数据量
                        childAry.forEach(t=>{
                            let subChildAry=resp1.data.filter(tm=>tm.parent==t.code);
                            if(t.itemNum>0){
                                if(selRegionCode.value=='-1'){
                                    selRegionCode.value=t.code;
                                    cityCode.value=t.code;
                                    proviceCode.value=element.code;
                                }
                                t.disabled=false;
                            }
                            else if(subChildAry.length>0 && subChildAry.filter(t=>t.itemNum>0).length>0){
                                t.disabled=false;
                            }
                            else{
                                t.disabled=true;
                            }
                            ///下下级地区是否有数据量
                            subChildAry.forEach(tm=>{
                                if(tm.itemNum>0){
                                    if(selRegionCode.value=='-1'){
                                        selRegionCode.value=tm.code;
                                        districtCode.value=tm.code
                                        cityCode.value=t.code;
                                        proviceCode.value=element.code;
                                    }
                                    tm.disabled=false;
                                }
                                else{
                                    tm.disabled=true;
                                }
                            })
                        })
                    });
                    regionList.value=resp1.data;
                }
                else{
                    regionList.value=null;
                }
                //console.log(resp2);
                if(resp2.code==20000){
                    //循环地区列表，对本地区没有数量和下级没有数量的地区禁用（不能点击）
                    resp2.data.filter(t=>t.parent=='' || t.parent=='0').forEach(element => {
                        let childAry=resp2.data.filter(t=>t.parent==element.code && t.parent!='0');
                        //本地区是否有数据量
                        if(element.itemNum>0){
                            if(selEcoIndustryCode.value=='-1'){
                                selEcoIndustryCode.value=element.code;
                                ecoIndustryCode.value=element.code;
                            }
                            element.disabled=false;
                        }
                        else if(childAry.length>0 && childAry.filter(t=>t.itemNum>0).length>0){
                            element.disabled=false;
                        }
                        else{
                            element.disabled=true;
                        }
                        //下级地区是否有数据量
                        childAry.forEach(t=>{
                            let subChildAry=resp2.data.filter(tm=>tm.parent==t.code);
                            if(t.itemNum>0){
                                if(selEcoIndustryCode.value=='-1'){
                                    selEcoIndustryCode.value=t.code;
                                    ecoIndustryBigCode.value=t.code;
                                    ecoIndustryCode.value=element.code;
                                }
                                t.disabled=false;
                            }
                            else if(subChildAry.length>0 && subChildAry.filter(t=>t.itemNum>0).length>0){
                                t.disabled=false;
                            }
                            else{
                                t.disabled=true;
                            }
                            ///下下级地区是否有数据量
                            subChildAry.forEach(tm=>{
                                if(tm.itemNum>0){
                                    if(selEcoIndustryCode.value=='-1'){
                                        selEcoIndustryCode.value=tm.code;
                                        ecoIndustryMiddleCode.value=tm.code
                                        ecoIndustryBigCode.value=t.code;
                                        ecoIndustryCode.value=element.code;
                                    }
                                    tm.disabled=false;
                                }
                                else{
                                    tm.disabled=true;
                                }
                            })
                        })
                    });
                    ecoIndustryList.value=resp2.data;
                }
                else{
                    ecoIndustryList.value=null;
                }
                if(selRegionCode.value!='-1' && selEcoIndustryCode.value!='-1'){
                    getItemDataListFun();
                }
                else{
                    loading.value=false;
                }
            }));
            
        }
       
        //获取下级地区指标数据量列表
        
        
        const getNextRegionItemNumFun=(type, regionCode,isdisabled)=>{
            if(isdisabled){
                return false;
            }
            if(type=="provice"){
                proviceCode.value=regionCode;
                cityCode.value='-1';
                districtCode.value='-1';
            }
            else if(type=="city"){
                cityCode.value=regionCode;
                districtCode.value='-1';
            }
            else{
                districtCode.value=regionCode;
            }
            selRegionCode.value=regionCode;
            getItemDataListFun();
        }
        //获取下级行业分类的指标数据量
        
        const getNextEcoIndustryItemNumFun=(type,code)=>{
             if(type=="ecoIndustry"){
                ecoIndustryCode.value=code;
                ecoIndustryBigCode.value='-1';
                ecoIndustryMiddleCode.value='-1';
            }
            else if(type=="ecoIndustryBig"){
                ecoIndustryBigCode.value=code;
                ecoIndustryMiddleCode.value='-1';
            }
            else{
                ecoIndustryMiddleCode.value=code;
            }
            selEcoIndustryCode.value=code;
            getItemDataListFun();
        }
        //列表和图表的切换
        const changIcon=(id)=>{
            selIconId.value=id;
            isDarkTheme.value=false;
            getItemDataListFun();
        }
        //获取指标项数据列表
        let dataList=ref(null);
        let selIconId=ref(1);//
        let isDarkTheme=ref(false)//图表深色模式
        let chartData=ref(null)//图表数据
        const getItemDataListFun=async()=>{
            isDarkTheme.value=false;
            chartData.value=null;
            dataList.value=null;
            maxVal.value='-';
            minVal.value='-';
            avgVal.value='-';
            middleVal.value='-';
            let params={
                pageIndex: 1,
                pageSize: 1,
                itemId:selItem.value.id,
                regionCode:selRegionCode.value,
                ecoIndustryCode:selEcoIndustryCode.value,
                isForecast:true
            }
            if(isChangeFavorite.value!=selItem.value.id+'_'+selEcoIndustryCode.value){
                isChangeFavorite.value=selItem.value.id+'_'+selEcoIndustryCode.value;
            }
            //console.log(params);
            //获取指标信息
            getItemInfoFun();
            //获取数据
            if(selIconId.value==1){//列表
                const result= await getItemDataListOfEcoIndustry(params);//当前时间地区指标项值
                //console.log(result);
                if( result.code==20000){
                    dataList.value=result.data
                    if(dataList.value.length>0){
                        analysisOfData(result.data.map(item=>item.itemValue).filter(item=>item));
                    }
                    
                }
                else{
                    dataList.value=null;
                }
            }
            else{//图表
                const res= await getEcoIndustryItemData_chart(params);//当前时间地区指标项值
                console.log(res);
                if(res.code==20000){
                    chartData.value=res.data;
                    initChart();
                    let filterAry= res.data.item.itemValue.filter(item=>item);
                    if(filterAry.length>0){
                        analysisOfData(res.data.item.itemValue.filter(item=>item));
                    }
                }
            }
            loading.value=false;
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'指标查询',JSON.stringify(params));
        }
        //计算数据的最大值，最小值、中位数、平均值
        let maxVal=ref('-');
        let minVal=ref('-');
        let avgVal=ref('-');
        let middleVal=ref('-');
        const analysisOfData=(ary)=>{
            if(ary.length<=0){
                return false;
            }
            //最大值
             maxVal.value=Math.max(...ary);//es6
            //最小值
            minVal.value=Math.min(...ary);
            //平均值
            //1、先求和
            var sumVal = ary.reduce(function(pre, curr) {
                return pre + curr;
            });
            //2、求个数，平均=和/个数
            avgVal.value=(sumVal/ary.length).toFixed(2);
            
            //中位数
            let newArr = ary.sort(function(a,b){return a-b})
            //console.log(ary)
            let len=newArr.length
            //console.log(len%2)
            if(len%2===0){
                middleVal.value=((newArr[parseInt(len/2-1)]+newArr[len/2])/2).toFixed(2)
            }else{
                middleVal.value=(newArr[parseInt(len/2)]).toFixed(2)
            }
        }
        //获取指标项信息
        let itemInfo=ref(null);//指标项信息
        const getItemInfoFun= async()=>{
            const result= await getItemInfoOfEcoIndustry(selItem.value.id);//当前时间地区指标项值
            //console.log(result);
            if(result.code==20000){
                itemInfo.value=result.data;
            }
        }
       
        //初始化图表
        let myChart=null;
        const initChart=()=>{
            if(myChart) {
                myChart.dispose();//销毁
            }
            nextTick(()=>{
                if(isDarkTheme.value==true){
                     myChart =echarts.init(document.getElementById("curve_l"),'dark');
                }
                else{
                     myChart =echarts.init(document.getElementById("curve_l"));
                }
                const colors = ['#5470C6', '#EE6666'];
                let option = {
                color: colors,
                // backgroundColor:'transparent',
                tooltip: {
                    trigger: 'axis',
                    // axisPointer: {
                    // type: 'cross'
                    // }
                },
                legend: {},
                dataZoom: [
                    {
                    type: 'inside',
                    height:20,
                    bottom:5,
                    start: 60,
                    end: 100
                    },
                    {
                    start: 60,
                    end: 100
                    }
                ],
                grid: {
                    top: 50,
                    bottom: 80
                },
                xAxis: [
                    {
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLine: {
                        onZero: false,
                        
                    },
                        axisLabel: {
                        formatter: function (params) {
                            return parseTime(new Date(params),'{y}');
                        }
                    },
                    axisPointer: {
                        label: {
                        formatter: function (params) {
                            return   parseTime(new Date(params.value),'{y}');
                        }
                        }
                    },
                    // prettier-ignore
                    data: chartData.value.date
                    },
                ],
                yAxis: [
                    {
                    type: 'value'
                    }
                ],
                series: [
                    {
                    name: '实际值',
                    type: 'line',
                    smooth: true,
                    emphasis: {
                        focus: 'series'
                    },
                    data: chartData.value.item.itemValue
                    },
                    {
                    name: '预测值',
                    type: 'line',
                    lineStyle:{
                        type:'dashed'
                    },
                    smooth: true,
                    emphasis: {
                        focus: 'series'
                    },
                    data: chartData.value.item.forecastVal
                    }
                ]};
                myChart.setOption(option);
            });
        }
        const download=async(btnCode)=>{
            //判断是否有权限
            if(!await getUserRightFun(btnCode,1,route.meta.menuId)){
                return false;
            }
            //获取图表的base64地址
            // var opts = {
            //     type: 'png', // 导出的格式，可选 png, jpeg
            //     pixelRatio: 1,// 导出的图片分辨率比例，默认为 1。
            //     //backgroundColor: string,// 导出的图片背景色，默认使用 option 里的 backgroundColor
            //     //excludeComponents: Array.<string>  // 忽略组件的列表，例如要忽略 toolbox 就是 ['toolbox'],一般也忽略了'toolbox'这栏就够了
            // }
            var resBase64 = myChart.getDataURL();   
            // console.log(resBase64);  
            let blob = base64ToBlob(resBase64); //new Blob([content]);
            const url = window.URL.createObjectURL(blob);            
            const link = document.createElement('a');            
            //console.log(fname);         
            link.href = url;     
            //console.log(url);       
            link.setAttribute('download', "图表.png");            
            document.body.appendChild(link);            
            link.click(); 
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,'下载',JSON.stringify({
                itemId:selItem.value.id,
                regionCode:selRegionCode.value,
                ecoIndustryCode:selEcoIndustryCode.value,
            }));
        }
        //加载数据
        getItemNumListFun(1);
        //记录用户操作日志
        addUserLog(route.name,route.fullPath,'加载',JSON.stringify({search:searchStr.value}));
        return{
            searchStr,
            loading,
            getItemNumListFun,
            itemList,
            itemTotal,
            itemInfo,
            selItem,
            selEcoIndustryCode,
            isMore,
            regionList,
            getRegionItemNumListFun,
            proviceCode,
            cityCode,
            
            getNextRegionItemNumFun,
            ecoIndustryList,
            ecoIndustryCode,
            ecoIndustryBigCode,
            ecoIndustryMiddleCode,
            getNextEcoIndustryItemNumFun,
            parseTimeOfCycle,
            dataList,
            selIconId,
            changIcon,
            isDarkTheme,
            initChart,
            download,
            maxVal,
            minVal,
            avgVal,
            middleVal,
            isChangeFavorite
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.is-disabled{
    color:#aaa;
    cursor:inherit !important;
}

.search-item-wrap{
    // height: 100%;
    height: 480px;
    margin-bottom: 10px;
}
.search-item-wrap li{
    line-height: 20px;
    padding: 5px 20px;
    cursor: pointer;
    span{
        display: inline-block;
        vertical-align: middle;
    }
    &:hover{
        background-color: rgba($themeColor, 0.05);
    }
}
.search-item-wrap li.active{
    background: rgba($themeColor, 0.1);
}
.search-item-num{
    color:#fc5531;
}
.search-item-wrap .search-item-name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 75%;
    display: inline-block;
}
.search-region-wrap{
    position: relative;
    padding-left: 60px;
    min-height: 120px;
}
.search-region-wrap li{
    float: left;
    margin:0 10px;
    line-height: 30px;
    padding: 0 5px;
    cursor: pointer;
    &.active .search-item-name{
        color:$specialtext-color;
        font-weight: bold;
    }
    &:not(.is-disabled):hover .search-item-name {
        color:$specialtext-color;
    }
}
.search-region-wrap .search-region-sub{
    margin-top: 20px;
    background-color: rgba($themeColor, 0.1);
    border-radius: 2px;
}
.search-title{
    font-weight: 600;
    font-size: 14px;
    border-bottom:2px solid $themeColor;
    margin-bottom: 10px;
    margin-top: 20px;
    span{
        background-color: $themeColor;
        line-height: 30px;
        display: inline-block;
        padding: 0 10px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        letter-spacing:1px;
        color: #fff;
    }
    i{
        float: right;
        margin-right:5px;
        line-height: 30px;
    }
    i.active{
        color: $specialtext-color;
    }
}
.flex_row {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around;
    background-color: rgba($themeColor, 0.1);
    border-radius: 2px;
    padding: 20px 0;
    .flex-col{
        text-align: center;
        .num{
            color: $specialtext-color;
            font-weight: 700;
            font-size: 24px;
            margin-top: 10px;
            display: block;
        }
    }
}
</style>